
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        














































.timeline__icon__info {
  margin-right: 10px;
  fill: #ff4b00;
}

.emphase-text {
  color: #ff4b00;
}

.timeline--header {
  width: 30%;
  min-width: 30em;
  display: flex;
  justify-content: space-between;
  margin: 5vh auto;
}

.timeline--header h2 {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 4rem;
  width: 35%;
}

.timeline--header h2:first-child {
  text-align: right;
}

.timeline--steps {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

.timeline--steps:before {
  content: '';
  width: 1px;
  height: 90%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border: 2px #b1b1b1;
  border-style: none dotted none none;

  @media screen and (max-width: 760px) {
    height: 89%;
  }
}

.timeline--number {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  /*font-size: 2.2rem;
  display: block;*/
  font-size: 27px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  line-height: 34px;
  background: black;
  text-align: center;
  border-radius: 100px;
  font-family: Martel, sans-serif;
}
.timeline--step {
  width: 42%;
  text-align: right;
  padding-bottom: 3vh;
  &:nth-child(6) .timeline--number,
  &:nth-child(8) .timeline--number {
    padding-top: 4px;
  }
}

.timeline--step h4 {
  display: block;
  align-items: baseline;
  color: #ff4b00;
  font-family: $ff-alt;
  font-weight: 600;
  margin: 0;
}

.timeline--step h4 img {
  width: 0.7em;
  height: 0.7em;
  margin: 0 10px;
}

.timeline--step p {
  margin: 0.5em 0;
}

.timeline--delay {
  background-image: url('../assets/svg/icons/clock.svg');
  background-position: top 3px left;
  background-repeat: no-repeat;
  padding-left: 25px;
  font-size: 1.5rem;
  color: grey;
  display: flex;
  align-items: center;
}

.timeline--step.right .timeline--number {
  background: #ff4b00;
}

.timeline--step.right {
  margin-left: auto;
  text-align: left;
}

@media screen and (max-width: 760px) {
  .timeline--header h2 {
    font-size: 3rem;
  }
  .timeline--steps {
    margin: 30px;
  }
  .timeline--step {
    width: 70vw;
    margin-left: 10vw !important;
    text-align: left !important;
  }
  .timeline--steps:before,
  .timeline--number {
    left: 0;
  }
  .timeline--header {
    display: none;
  }
  .timeline--main {
    margin-top: 1em;
  }
}
