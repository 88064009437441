
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        













































.faq-contact__list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.faq-contact__list__item {
  margin-right: 2rem;

  a {
    display: flex;
    align-items: center;
    color: $c-default;
    text-decoration: none;

    svg {
      margin-right: 1rem;
    }

    span {
      font-size: 1.4rem;
    }
  }
}

.faq-contact__info {
  font-size: 1.3rem;
}

.orange-text {
  color: $c-orange;
}
