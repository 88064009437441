
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





.cookieTable {
  display: block;
}
