
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



















.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  text-decoration: none;
  background-color: $c-white;
  border: 1px solid $c-gray-lighter;
  border-radius: 30px;
  margin-right: 20px;
  transition: background-color 0.15s, color 0.15s, border 0.15s;

  &:disabled {
    opacity: 0.7;
  }

  &.is-valid {
    background-color: $c-green;
  }

  &:not(:disabled):hover {
    cursor: pointer;
    background-color: rgba($c-main-primary, 5);
    border: 1px solid rgba($c-main-primary, 5);

    span {
      color: $c-white;
    }
  }

  @include mq(l) {
    padding: 1.2rem 2rem;
  }
}

.btn__label {
  display: inline-block;
  color: $c-main-primary;
  font-size: 2.3rem;
  line-height: 1.5;
  text-align: left;

  @include mq(l) {
    font-size: 2.3rem;
  }
}

.btn__text {
  text-align: left;
  color: $c-black;
  font-size: 1.5rem;

  span {
    color: $c-white;
  }
}

.btn__icon {
  width: 100px;
  height: 100px;
  padding-left: 1rem;
  fill: $c-main-primary;
  transition: transform 0.15s;

  [class*='btn']:not(:disabled):hover & {
    transform: translateX(3px);
  }
}
