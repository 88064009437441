
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






















.macommune .textarea {
  textarea {
    border-color: #bababa;
  }

  .headline {
    color: #535356;
    font-size: 1.6rem;

    @media screen and (max-width: 767px) {
      margin-bottom: 0.5em;
    }
  }
}

.textarea {
  position: relative;
  margin: 0 0 3rem;

  @include mq(m) {
    margin: 0 0 6rem;
  }
}

.label {
  display: block;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  min-height: 10rem;
  font-size: 1.8rem;
  border: 0.1rem solid $c-gray-lighter;

  &::placeholder {
    color: $c-gray-lighter;
  }
}

.textarea-gravity textarea {
  min-height: 20rem;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 5%);
  padding: 15px;

  &::placeholder {
    font-weight: normal;
    color: $c-gray;
  }
}

.my-commune-address .macommune.textarea-gravity textarea {
  border-radius: 5px !important;
}
