
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






















































































































































































































.dark-grey {
  color: $c-gray-dark;
}

.info-bullet {
  fill: $c-main-primary;
  margin-right: 10px;
}

.tempory__technique {
  font-size: 1.6rem;
  font-family: $ff-default;
  color: $c-gray-dark;

  p {
    margin-bottom: 10px;
  }
}

.title__bar {
  border-bottom: 1px solid $c-black;
  font-family: $ff-alt;
  margin-top: 35px;
  margin-bottom: 35px;
  padding-bottom: 0.25em;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 45px;

  thead {
    border-bottom: 1px solid $c-gray-dark;
  }

  tbody:before {
    content: '.';
    color: white;
    display: block;
  }

  th {
    color: $c-gray-dark;
    font-family: $ff-default;
    font-size: 1.6rem;
    font-weight: 400;
    text-align: left;
    padding-bottom: 5px;
    width: 25%;
  }

  td {
    font-family: $ff-default;
    text-align: right;
    white-space: nowrap;
    &:not(:last-child) {
      padding-right: 2rem;
    }
  }
}

.info__temporary {
  list-style-type: decimal;
  font-size: 14px;
  color: $c-gray-dark;
  margin-bottom: 30px;
  margin-left: 10px;
}

.check__svg {
  fill: $c-green;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.5rem;
}

div.select {
  ::v-deep {
    button.option.placeholder {
      color: $c-orange;
      font-family: $ff-default;
      font-size: 1.6rem;
      &:hover {
        color: $c-orange;
      }
    }
  }
}
div.select.is-open {
  ::v-deep {
    ul.options {
      border-radius: 3px;
      border: 1px solid $c-gray;
      box-shadow: 0 0 5px rgba($c-black, 0.2);

      a.option {
        color: $c-orange;
        font-family: $ff-default;
        font-size: 1.6rem;
        &:hover {
          color: $c-black;
        }
      }
    }
  }
}
