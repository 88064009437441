
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        
















































































































































































































































.block-content-image {
  border-radius: 30px;
  margin-right: 30px;
  width: 285px;
  padding: 20px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.28);
}

.block-content-image.clickable:hover {
  cursor: pointer;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.28);
}

.block-content-image.clickable:hover .image {
  transform: scale(1.05);
}

div.img-block .title {
  color: $c-orange;
  font-weight: bolder;
  font-size: 20px;
  display: flex;
}

div.img-block .icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.tag {
  display: block;
  color: $c-orange;
  font-size: 0.8em;
  margin-bottom: 10px;
}
