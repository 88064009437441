
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        













































































.container {
  position: relative;

  @include mq(l) {
    margin: 0;
  }
}

.container-sticky {
  right: 10%;
  z-index: 1;
  height: 80%;

  @include mq(l) {
    margin: 0;
  }

  &:not(.container-spacing-left) {
    margin-left: 40px;
    @include mq(l) {
      margin: 0;
    }
  }
}

.container-fixed {
  position: absolute;
  z-index: 9999;

  @include mq(l) {
    margin: 0;
  }
}

.container-spacing-left {
  @include mq(l) {
    margin-left: 40px;
  }
}

.tip__box-fixed {
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.15);
  position: fixed;
  bottom: 10%;
  right: 10%;

  @include mq(l) {
    border-radius: 20px;
    @include fluid(
      width,
      (
        xs: 320px,
        xl: 350px,
      )
    );
    padding: 3rem;
    height: fit-content;
  }

  @include mq($until: l) {
    background-color: $c-white;
    cursor: pointer;
    display: grid;
    align-content: center;
    justify-content: center;
    border-radius: 50%;
    min-width: 50px;
    min-height: 50px;
    max-width: 320px;
  }
}

.tip__box-sticky {
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.15);
  position: -webkit-sticky;
  position: sticky;
  top: 150px;
  width: 100%;
  min-width: 50px;
  padding: 3rem;
  border-radius: 20px;
  @include fluid(
    max-width,
    (
      xs: 320px,
      xl: 350px,
    )
  );

  @include mq(l) {
    height: fit-content;
  }

  @include mq($until: l) {
    background-color: $c-white;
    cursor: pointer;
    display: grid;
    align-content: center;
    justify-content: center;
    min-height: 50px;
  }
}

.tip__box {
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.15);
  @include fluid(
    width,
    (
      xs: 320px,
      xl: 350px,
    )
  );
  padding: 3rem;
  height: fit-content;
  border-radius: 20px;
}

.is-open {
  border-radius: 20px;
  padding: 3rem;
  height: fit-content;
}

.tip__title {
  color: $c-main-primary;
}

.tip__icon {
  @include mq(m) {
    margin-bottom: 5px;
  }
  fill: $c-main-primary;
  width: 2.4rem;
  height: 2.4rem;
}

.tip__close {
  border: 1px solid $c-main-primary;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: $c-main-primary;
  height: 4rem;
  right: 1.5rem;
  top: 1.5rem;
  position: absolute;
  width: 4rem;
}

.tip__button {
  border-radius: 20px !important;

  ::v-deep .action__label {
    display: flex;
    align-items: center;
    @include fluid(
      font-size,
      (
        xs: 15px,
        xl: 18px,
      )
    );
    font-weight: 500;

    span {
      margin-left: 5px !important;
      font-weight: 400;
    }
  }
}
