
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        































.info__svg {
  fill: $c-gray-light;
  width: 100px;
  height: 100px;
  margin-right: 20px;
}

.documents__list {
  list-style-type: disc;
  margin: 20px 20px;
}

.connection__documents {
  border: 1px solid $c-gray-light;
  padding: 20px 30px;
  display: flex;
}
