
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





























.datepicker {
  ::v-deep {
    .vdp-datepicker__calendar {
      box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.5);
      border: 0;
      border-radius: 10px;
      padding: 1em;
    }

    .cell {
      font-size: 1.4rem;
      border-radius: 50%;
      background: white;
    }

    .input-datepicker {
      width: 100%;
      font-size: 1.8rem;
      background: transparent;
      position: relative;
      border: 0;
      border-bottom: 1px solid $c-gray-lighter;

      &::placeholder {
        @extend %fw-light;

        color: $c-gray-light;
      }
    }
  }
}

.fill-background {
  ::v-deep {
    .cell {
      &.selected {
        background: $c-main-primary;
      }

      &:hover:not(.disabled) {
        background: $c-main-primary;
        border: 1px solid $c-main-primary;
      }

      &.disabled {
        background: #c1c1c1;
      }

      &.cell:not(.blank):not(.disabled).day:hover {
        border: 1px solid $c-main-primary;
      }
      &.cell:not(.blank):not(.disabled).month:hover {
        border: 1px solid $c-main-primary;
      }
      &.cell:not(.blank):not(.disabled).year:hover {
        border: 1px solid $c-main-primary;
      }
    }
  }
}

.empty-background {
  ::v-deep {
    .cell {
      &.selected {
        color: $c-main-primary;
        background: transparent;
        border: 1px solid transparent;
      }

      &:hover:not(.disabled) {
        color: $c-main-primary;
        background: transparent;
        border: 1px solid transparent;
      }

      &.cell:not(.blank):not(.disabled).day:hover {
        border: 1px solid transparent;
      }
      &.cell:not(.blank):not(.disabled).month:hover {
        border: 1px solid transparent;
      }
      &.cell:not(.blank):not(.disabled).year:hover {
        border: 1px solid transparent;
      }
    }
  }
}
