
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





































.content__title {
  b {
    font-weight: 600;
  }
}

.faq-page {
  position: relative;

  .container-sticky {
    right: 4.1666666667%;

    @media only screen and (min-width: 85em) {
      right: 8.3333333%;
    }
  }

  ::v-deep {
    .main-title__title {
      // prettier-ignore
      @include fluid(font-size, (xxs: 28px, m: 48px));
    }

    .accordion {
      padding: 0 !important;
    }
  }
}

.search-module {
  ::v-deep input {
    width: calc(100% - 100px);
    padding: 15px 20px 15px;
    border: 1px solid lightgrey;
    border-radius: 100px 0 0 100px;
    font-size: 14px;
    font-style: italic;
  }

  ::v-deep .icon-btn {
    background: #fff url('../../assets/svg/icons/vector.svg') no-repeat left
      10px center;
    background-size: 20px 20px;
    width: auto;
    height: 53px;
    padding-left: 40px;
    border-radius: 0 100px 100px 0;
    cursor: pointer;
    font-weight: bolder;
    color: $c-gray-darkest;
  }
}
