
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

















.double-cta {
  //@include gradient-lines;
  //prettier-ignore
  //@include fluid(padding-bottom, (xxs: 60px, xxl: 100px));
  .col-s-10 {
    //@include fluid(flex-basis, (s: calc(50% - 13px)));
    /*
    @include mq(s) {
      flex-basis: calc(50% - 13px);
      max-width: calc(50% - 13px);
    }
    @include mq(m) {
      flex-basis: 100%;
      max-width: calc(100%);
    }*/
  }
}

.double-cta-inner {
  // prettier-ignore
  // @include fluid(padding-top, (xxs: 10px, xxl: 40px));
  // prettier-ignore
  //@include fluid(padding-bottom, (xxs: 10px, xxl: 60px));

  //margin-top: -13.5rem;
  //background: $c-white;

  @include fluid(
    column-gap,
    (
      xxs: 25px,
      xxl: 25px,
    )
  );
  row-gap: 25px;
  //@include fluid(row-gap, (s: 25px, l:0));
}

.cta {
  ::v-deep {
    .content {
      position: relative;
    }

    .content__link {
      @include get-all-space;
    }

    .action__label {
      display: none;
    }
  }
}
.double-cta-inner {
  ::v-deep {
    .row-double {
      display: flex;
      flex-flow: row nowrap;
      flex-direction: row;
    }
    .pict {
      img {
        margin-right: 40px;
        @include mq($until: m) {
          height: auto;
          margin-right: 20px;
          max-width: 80px;
        }
      }
    }
    .cta-rich .pict + .col {
      .content__headline {
        @include mq(m) {
          padding-top: 35px;
        }
      }
    }
  }
}
/*
.footer {
  width: 100%;
  padding-bottom: 2rem;
  border-bottom: 1px solid $c-black;
}

.footer__icon {
  width: 3.2rem;
  height: 3.2rem;
  padding: 1rem;
  background: $c-orange;
  border-radius: 50%;
  fill: $c-white;
  transform: rotate(-90deg);
  transition: transform 0.2s;

  .cta:hover & {
    transform: translateX(1rem) rotate(-90deg);
  }
}
*/
